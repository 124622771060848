import logo from './logo.svg';
import './App.css';
import { axiosInstance } from './api';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const axios = require('axios');
function App() {
  const { id } = useParams();
  
  const navigate = useNavigate();
  useEffect(() => {
    console.log(id);
    const data = async () => {
      try {
        const response = await axiosInstance.get(`users/submitLead/${id}`);
        const offer_url = response.data.offerurl;
        console.log("respomse"+response.data);
        console.log("offerurl: " + offer_url);
        window.location.href = offer_url;
      } catch (error) {
        console.error(error);
      } 
    };
    data();
  }, [id,navigate]);

  return (
    <div className="App">
      <header className="App-header">
        <img src = {require('../src/assets/images/animation.gif')} style={{height:"150px",width:"150px"}} />3
        
      </header>
    </div>
  );
}

export default App;
